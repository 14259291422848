<!-- <footer class="main-footer">
  <a mat-raised-button color="accent" href="https://themeforest.net/item/egret-angular-4-material-design-admin-template/20161805">Buy Egret</a>
  <span class="m-auto"></span>
  Design & Developed by <a href="https://ui-lib.com"> UI Lib</a>
</footer> -->

<footer class="main-footer" style="font-weight: lighter;font-size: 12px;color:hsla(0,0%,100%,.40)!important" style="display:none">
  Version 1.0
  <span class="m-auto"></span>
  Copy<mat-icon class="copyright" style="font-size: 13px;position:relative; bottom:-7px; right: -5px;">copyright</mat-icon>2023, Developed by HANUMAN IT
</footer>